module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mondaic.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mondaic - Full Waveform Solutions","short_name":"Mondaic","start_url":"/","background_color":"#ffffff","theme_color":"#F6C44B","display":"standalone","icon":"src/images/logos/mondaic_logo_512x512_no_text.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"365e755f42bba5bb08281fcd21fcc8e2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#7331bf","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about_us","/products","/services","/docs"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-98104769-2","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"resoectDNT":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"pages":"/builds/Mondaic/Code/infrastructure/website/src/templates/mdx_template.tsx"},"extensions":[".mdx",".md"],"remarkPlugins":[null,null],"gatsbyRemarkPlugins":[{"resolve":"/builds/Mondaic/Code/infrastructure/website/node_modules/gatsby-remark-images","id":"4a359a36-fef3-5c49-a0f9-45c24a5fd434","name":"gatsby-remark-images","version":"6.11.0","modulePath":"/builds/Mondaic/Code/infrastructure/website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":590},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/Mondaic/Code/infrastructure/website","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    }]
