import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/Code/infrastructure/website/src/templates/mdx_template.tsx";
import { Embed } from 'semantic-ui-react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Extra-planetary Imaging`}</h1>
    <p>{`The geometrical flexibility of Salvus brings seismic imaging techniques
to planets, asteroids and other extraterrestrial bodies. Accurate
modeling of surface topography and solid/fluid coupling in the interior
are crucial to understand the seismicity of these objects. For instance,
Salvus is used by NASA’s Insight mission to run large-scale simulations
for various scenarios of the interior structure of Mars.`}</p>
    <Embed id="465482106" source="vimeo" autoPlay={false} placeholder="https://vumbnail.com/465482106.jpg" iframe={{
      allowFullScreen: true
    }} mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      