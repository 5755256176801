import { Preamble, WhereToInstall, InstallingCondaMamba, RunningDownloader, SiteSetup, ContinueLearning } from "../../../../src/doc_pages/installation/platform_specific/common_instructions";
import * as React from 'react';
export default {
  Preamble,
  WhereToInstall,
  InstallingCondaMamba,
  RunningDownloader,
  SiteSetup,
  ContinueLearning,
  React
};