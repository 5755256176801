import * as React from 'react';

class HighlightedSalvusProduct extends React.Component<
  { background_color: string; foreground_color: string; name: string },
  {}
> {
  public render() {
    return (
      <span
        css={{
          color: this.props.foreground_color,
          backgroundColor: this.props.background_color,
        }}
      >
        Salvus
        <i>
          <b>{this.props.name}</b>
        </i>
      </span>
    );
  }
}

export const SalvusCompute: React.FC = () => {
  return (
    <HighlightedSalvusProduct
      background_color="#FFC912"
      foreground_color="black"
      name="Compute"
    />
  );
};

export const SalvusMesh: React.FC = () => {
  return (
    <HighlightedSalvusProduct
      background_color="#E49254"
      foreground_color="black"
      name="Mesh"
    />
  );
};

export const SalvusPy: React.FC = () => {
  return (
    <HighlightedSalvusProduct
      background_color="#7331BF"
      foreground_color="white"
      name="Py"
    />
  );
};

export const SalvusOpt: React.FC = () => {
  return (
    <HighlightedSalvusProduct
      background_color="#C900C5"
      foreground_color="white"
      name="Opt"
    />
  );
};

export const SalvusFlow: React.FC = () => {
  return (
    <HighlightedSalvusProduct
      background_color="#0058C8"
      foreground_color="white"
      name="Flow"
    />
  );
};
