import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Highlighter from '../mdx_blocks/highlighter';

export class SalvusFlowExampleTOML extends React.Component<
  { name: string },
  {}
> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allFile(
              filter: {
                relativeDirectory: {
                  eq: "installation/salvus_flow/example_sites"
                }
                sourceInstanceName: { eq: "doc_pages" }
                extension: { eq: "toml" }
              }
            ) {
              edges {
                node {
                  name
                  fields {
                    fileContent
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          let d = data.allFile.edges.filter(
            (n) => n.node.name === this.props.name
          );
          if (d.length != 1) {
            throw `Could not find site '${this.props.name}'.`;
          }
          let content = d[0].node.fields.fileContent;
          return (
            <div css={{ marginBottom: '2em' }}>
              <Highlighter css={{ fontSize: '80%' }} language="toml">
                {content}
              </Highlighter>
            </div>
          );
        }}
      />
    );
  }
}
