import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/Code/infrastructure/website/src/templates/mdx_template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Salvus`}</h1>
    <p>{`Needs more content!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      