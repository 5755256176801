import * as React from 'react';

import { H2, H3, H4 } from '../../../components/mdx_blocks/header';

import Highlighter from '../../../components/mdx_blocks/highlighter';

import {
  SalvusCompute,
  SalvusFlow,
  SalvusMesh,
  SalvusOpt,
  SalvusPy,
} from '../../../components/doc/names';

interface PlatformProps {
  platform: 'linux' | 'macos_intel' | 'macos_arm' | 'windows' | 'windows_wsl';
  sectionNumber: number | string;
}

const platformInlinePrettyNames: {
  [key in PlatformProps['platform']]: string;
} = {
  linux: 'Linux',
  macos_intel: 'Intel macOS',
  macos_arm: 'Apple Silicon macOS',
  windows: 'Windows',
  windows_wsl: 'WSL',
};
const platformPrettyNames: {
  [key in PlatformProps['platform']]: string;
} = {
  linux: 'Linux',
  macos_intel: 'macOS (Intel)',
  macos_arm: 'macOS (Apple Silicon)',
  windows: 'Windows',
  windows_wsl: 'Windows (WSL)',
};

export class Preamble extends React.Component<PlatformProps> {
  public render() {
    const { platform } = this.props;
    const prettyPlatformName = platformPrettyNames[platform];
    const platformInlinePrettyName = platformInlinePrettyNames[platform];

    return (
      <>
        <H2>Installing Salvus on {prettyPlatformName}</H2>

        <p>
          We recommend the following steps to install Salvus on{' '}
          <strong>{platformInlinePrettyName}</strong> systems. This page
          contains a general process -- however some steps have a deeper dive
          under the{' '}
          <a href="/docs/installation/advanced_topics">advanced topics</a>{' '}
          accessed via the sidebar menu.
        </p>
      </>
    );
  }
}

export class WhereToInstall extends React.Component<{ sectionNumber: number }> {
  public render() {
    const { sectionNumber } = this.props;

    return (
      <>
        <H3>{String(sectionNumber)}. Where to install</H3>
        <p>
          The first step to install Salvus, regardless of system, is to figure
          out where every component of it will run. The most common case is to
          have a full installation locally and additionally install a version of{' '}
          <SalvusCompute /> on a larger workstation or cluster. Details on where
          to run Salvus for different scenarios can be found on{' '}
          <a href="/docs/installation/where_to_run_things">the relevant page</a>
          .
        </p>
      </>
    );
  }
}

export class InstallingCondaMamba extends React.Component<PlatformProps> {
  public render() {
    const { platform, sectionNumber } = this.props;
    const prettyPlatformName = platformPrettyNames[platform];
    const platformInlinePrettyName = platformInlinePrettyNames[platform];

    return (
      <>
        <H3>{String(sectionNumber)}. Setting up your Python environment</H3>
        <p>
          Salvus runs a great deal of its functionality through Python (
          <SalvusFlow />, <SalvusMesh />, <SalvusOpt />, all contained within{' '}
          <SalvusPy />
          ). For it to be able to do that, it needs a compatible version of
          Python and dependencies. We ensure this compatibility by using{' '}
          <i>conda-based package managers</i>, then installing preset versions
          of Python and dependent packages.
        </p>
        <H4>Installing a package manager</H4>
        <p>
          On <strong>{platformInlinePrettyName}</strong> systems, we recommend
          to use the Miniforge distribution together with{' '}
          <a href="https://mamba.readthedocs.io/en/latest/">Mamba</a> as package
          manager. First, install Miniforge from{' '}
          <a href="https://github.com/conda-forge/miniforge">
            Miniforge's GitHub
          </a>
          . After installation, this program can be accessed from your shell by
          simply running it as:
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          mamba --version
        </Highlighter>
        <p>
          The default configuration installs a Python executable into something
          called a <strong>"Conda environment"</strong>. Such an environment is
          a way to install different version of Python and dependencies without
          them interfering. Most shells indicate that you are inside an
          environment by prepending the shell prompt with the environment name.
          Restarting your shell after a typical Miniforge installation will show
          you the default activated environment:
        </p>
        <pre>{'(base) > ...'}</pre>
        <p>
          This default environment will not be further used by <SalvusPy />.
        </p>
        <H4>
          Creating a <strong>salvus</strong> environment with dependencies
        </H4>
        <p>
          After setting up a way for you to run the command Mamba (or Conda if
          you opted for a different distribution), you need to download the list
          of Python dependencies, i.e. the 'specs' or 'specifications', and use
          this to create a new Conda environment. Conda and Mamba can directly
          read this file to install the appropriate Python version and external
          versions packages (of exact versions) required by <SalvusPy />.
        </p>
        <p>
          On <strong>{platformInlinePrettyName}</strong> systems, this file is
          easily obtained by running the program curl:
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          curl https://mondaic.com/environment-py311.yml -o environment.yml
        </Highlighter>
        <p>
          which will download it to the current directory in which you have your
          shell opened.{' '}
          {platform === 'windows' && (
            <span>
              On <strong>Windows</strong>, this is done easiest through the
              provided Miniforge shell. Simply open the Miniforge shell from
              your start menu and run the above command.{' '}
            </span>
          )}
          You can also{' '}
          <a href="https://mondaic.com/environment-py311.yml">
            navigate to the file in your browser
          </a>{' '}
          to download it to your downloads folder.
        </p>
        {/* Special handling for Windows WSL */}
        {platform === 'windows_wsl' && (
          <p>
            On <strong>Windows (WSL)</strong>, if you use the browser to
            download the YAML file, you will need to move it into your WSL
            environment. The easiest way is to copy the file from your Windows
            Downloads folder into the WSL file system, as described on{' '}
            <a href="https://learn.microsoft.com/en-us/windows/wsl/filesystems">
              this support page from Microsoft
            </a>
            .
          </p>
        )}
        <p>
          What's left is to use the downloaded spec file to create a new Conda
          environment. To do this, run this in your shell:
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          {`
# Create a new environment with all required dependencies.
mamba env create -n salvus -f environment.yml
          `}
        </Highlighter>
        <p>
          This creates a new Python environment with the name `salvus`. You are
          free to change the name of the environment in your command, just
          ensure you note down for yourself what you name it.
        </p>
        <p>
          To use this environment for the next steps of the installation
          process, and to use Salvus at all, make sure to activate this
          environment in your shell:
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          {`
# Activate the created environment.
mamba activate salvus
          `}
        </Highlighter>
      </>
    );
  }
}

export class RunningDownloader extends React.Component<PlatformProps> {
  public render() {
    const { platform, sectionNumber } = this.props;
    const prettyPlatformName = platformPrettyNames[platform];
    const platformInlinePrettyName = platformInlinePrettyNames[platform];

    return (
      <>
        <H3>{String(sectionNumber)}. Running the Mondaic Downloader</H3>
        <p>The next step is to obtain two sets of files:</p>
        <ul>
          <li>
            <SalvusCompute /> related binaries. These files include the
            computational part of Salvus itself, optionally its 64-bits
            precision variant, and binaries required for parallel execution.
          </li>
          <li>
            <SalvusPy /> related distribution files. This simply is the Python
            API of Salvus rolled up into a distributable wheel.
          </li>
        </ul>
        <p>
          Since both of these files require to be compiled on a per-platform
          basis, you need to obtain them specifically for{' '}
          <strong>{platformInlinePrettyName}</strong>. Selecting the right set
          of files to download is mostly automated through the{' '}
          <strong>Mondaic Downloader</strong>, with{' '}
          <a href="/docs/installation/mondaic_downloader">
            full details available here
          </a>
          .
        </p>
        <p>
          To run this helper program on{' '}
          <strong>{platformInlinePrettyName}</strong> and obtain the relevant
          files, run the following:
        </p>
        {platform === 'windows' ? (
          <Highlighter language="shell" className={'language-shell'}>
            powershell -command "iex (New-Object
            System.Net.WebClient).DownloadString('https://get.mondaic.com/win')"
          </Highlighter>
        ) : (
          <Highlighter language="shell" className={'language-shell'}>
            bash -c "$(curl -sSL https://get.mondaic.com)"
          </Highlighter>
        )}
        <p>
          After evaluating the end-user license agreement, ensuring the user has
          a license, a few system configuration steps are run through. The
          Mondaic Downloader is smart enough to select the correct{' '}
          <a href="/docs/installation/microarchitecture">microarchitecture</a>{' '}
          and platform automatically. After this, you can select your release
          version.
        </p>
        <p>
          Finally, the installer allows one to select which files exactly are
          downloaded:
        </p>
        <ul>
          <li>
            <strong>Salvus</strong>: the single precision version of
            SalvusCompute. This is needed to run Salvus in normal operation.
          </li>
          <li>
            <strong>SalvusF64</strong>: the double precision version of
            SalvusCompute. This is typically not needed to run Salvus in normal
            operation, but might be useful for research purposes. It is less
            performant but more precise.
          </li>
          <li>
            <strong>SalvusPy</strong>: the Salvus Python package. This is needed
            to run Salvus in normal operation, but typically not needed on
            remote systems to which simulations are dispatched.
          </li>
          <li>
            <strong>mpi</strong>: the MPI distribution. This is needed to run
            Salvus in normal operation, but it is not recommended on systems
            that provide their own, like HPC systems
          </li>
        </ul>
        <p>
          One can navigate with the keyboard arrows and (de)select files using
          space.
        </p>
        <p>
          The last part of this step is to install <SalvusPy /> into the Python
          environment created before. This is done by running the pip program
          (available via the Conda environment) on the just-downloaded Python
          wheel of Salvus. In the following two commands ensure that{' '}
          <strong>
            you update the environment name to match the name you gave
          </strong>{' '}
          and{' '}
          <strong>
            you update the path to the Python wheel to match what you entered
          </strong>
          :
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          {`
# Ensure you update the environment name if necessary.
mamba activate salvus
` +
            (platform === 'windows'
              ? `# On Windows, use the Miniforge terminal to ensure compatibility
# with the following command, and ensure you update the download
# path if necessary.
pip install %USERPROFILE%\\Salvus\\python_packages\\salvus-*.whl`
              : `# Ensure you update the download path if necessary.
pip install ~/Salvus/python_packages/salvus-*.whl`)}
        </Highlighter>
      </>
    );
  }
}

export class SiteSetup extends React.Component<PlatformProps> {
  public render() {
    const { platform, sectionNumber } = this.props;
    const prettyPlatformName = platformPrettyNames[platform];
    const platformInlinePrettyName = platformInlinePrettyNames[platform];

    return (
      <>
        <H3>{String(sectionNumber)}. Setting up your Salvus site</H3>
        <p>
          The final step in this process is to set up a computational site for{' '}
          <SalvusCompute /> to run on. A site determines how the computational
          part of Salvus is run, and what resources it is allocated. The
          functionality that orchestrates the dispatching of simulations, the
          allocation of resources and the retrieval of results is collected in{' '}
          <SalvusFlow />, A detailed explanation on what these sites are can be
          found on{' '}
          <a href="/docs/installation/salvus_flow_configuration">
            the page on <SalvusFlow /> Configurations
          </a>
        </p>
        <p>
          To streamline this process, the command line interface to salvus
          (aptly named `salvus-cli`) allows one to interactively create a new
          site from your shell:
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          {`# Update the conda environment name if necessary
mamba activate salvus
salvus-cli add-site`}
        </Highlighter>
        <p>
          The default and maximum ranks relate to the parallelism allowed on
          your systems. Higher values up to the maximum your system is capable
          of will typically result in the better performance. We found that the
          optimal on <strong>{platformInlinePrettyName}</strong> can be found by
          running the following:
        </p>
        <Highlighter language="shell" className={'language-shell'}>
          {(platform === 'macos_intel' || platform === 'macos_arm'
            ? `
# macOS command to get physical CPU details
sysctl hw.perflevel0.physicalcpu
`
            : '') +
            (platform === 'linux'
              ? `
# Linux command to get CPU details
lscpu | grep -E '^Thread|^Core|^Socket|^CPU\\('
`
              : '') +
            (platform === 'windows' || platform === 'windows_wsl'
              ? `
# Windows native command to retrieve CPU details
# Run this in PowerShell
Get-CimInstance -ClassName Win32_Processor | Select-Object -Property Name,NumberOfCores,NumberOfLogicalProcessors
`
              : '')}
        </Highlighter>
        <p>
          Specifically, the optimal on any system is the number of performant
          physical cores.{' '}
          {platform === 'macos_arm' && (
            <span>
              On <strong>{platformInlinePrettyName}</strong>, this is not the
              total number of cores, as some of the cores on your system will be
              lower power cores. Using those for parallelism will slow down all
              cores to their speeds. The command above will only return the
              number of performance cores.
            </span>
          )}
        </p>
      </>
    );
  }
}

export class ContinueLearning extends React.Component<PlatformProps> {
  public render() {
    const { sectionNumber } = this.props;

    return (
      <>
        <H3>{String(sectionNumber)}. Start to learn Salvus</H3>
        <p>
          Salvus should be all ready by now so time to learn how to use it. We
          recommend to start with{' '}
          <a href="/docs/examples/tutorials/getting_started/tutorial_part1">
            the getting started tutorial series
          </a>
          .
        </p>
      </>
    );
  }
}
