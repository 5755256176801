import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/Code/infrastructure/website/src/templates/mdx_template.tsx";
import { Embed } from 'semantic-ui-react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Seismology`}</h1>
    <p>{`Seismic waves can be used to study many aspects of the Earth's crust,
mantle, and core. Salvus can mesh, simulate, and invert on all scales
ranging from small scale basin level studies to the full globe. This
naturally includes complexities like oceans, as well as internal and
external topographies.`}</p>
    <Embed id="467201570" source="vimeo" autoPlay={false} placeholder="https://vumbnail.com/467201570.jpg" iframe={{
      allowFullScreen: true
    }} mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      