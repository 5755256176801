import { Message } from 'semantic-ui-react';
import { SalvusCompute } from "../../../../src/components/doc/names";
import { Preamble, WhereToInstall, InstallingCondaMamba, RunningDownloader, SiteSetup, ContinueLearning } from "../../../../src/doc_pages/installation/platform_specific/common_instructions";
import * as React from 'react';
export default {
  Message,
  SalvusCompute,
  Preamble,
  WhereToInstall,
  InstallingCondaMamba,
  RunningDownloader,
  SiteSetup,
  ContinueLearning,
  React
};